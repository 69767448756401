<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title pt-2"><span class="text-bold">{{ $t('cms.category.name') }}:</span> {{ resourceDetails.title }}</h3>
      <div class="float-right">
        <b-button @click="createOrEditResource" size="sm" variant="success" class="mr-2">
          {{ $t('cms.btn.save_resource') }}
        </b-button>
        <router-link
            :to="{ name: 'resources-list' }">
          <b-button size="sm" variant="outline-primary">
            {{ $t('cms.btn.back_to_resources_list') }}
          </b-button>
        </router-link>
      </div>
    </div>
    <b-tabs card>
      <template #tabs-end>
        <b-nav-item :active="$route.name === 'cms-general' || $route.name === 'cms-general-create'"
                     @click="goToPage(idFromUrl ? 'cms-general' : 'cms-general-create')">
          {{$t('cms.content.general')}}
        </b-nav-item>
<!--        <b-nav-item :active="$route.name === 'cms-content' || $route.name === 'cms-content-create'"-->
<!--                    @click="goToPage(idFromUrl ? 'cms-content' : 'cms-content-create')">-->
<!--          {{$t('cms.content.content')}}-->
<!--        </b-nav-item>-->
        <b-nav-item :active="$route.name === 'cms-advanced' || $route.name === 'cms-advanced-create'"
                    @click="goToPage(idFromUrl ? 'cms-advanced' : 'cms-advanced-create')">
          {{$t('cms.content.advanced')}}
        </b-nav-item>
        <b-nav-item :active="$route.name === 'cms-translations' || $route.name === 'cms-translations-create'"
                    @click="goToPage(idFromUrl ? 'cms-translations' : 'cms-translations-create')">
          {{$t('cms.content.translations')}}
        </b-nav-item>
        <b-nav-item :active="$route.name === 'cms-child-elements' || $route.name === 'cms-child-elements-create'"
                    @click="goToPage(idFromUrl ? 'cms-child-elements' : 'cms-child-elements-create')">
          {{$t('cms.content.child_elements')}}
        </b-nav-item>
        <b-nav-item :active="$route.name === 'cms-attributes' || $route.name === 'cms-attributes'"
                    @click="goToPage(idFromUrl ? 'cms-attributes' : 'cms-attributes')">
          {{$t('cms.content.attributes')}}
        </b-nav-item>
      </template>
      <template #default>
        <div class="tab-pane active card-body">
          <router-view></router-view>
        </div>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import {mapState} from "vuex";
import alertify from "alertifyjs";

export default {
  props: {
    isCreateType: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    ...mapState({
      resourceDetails: (state) => state.cms.resourceDetails,
      locale: (state) => state.cms.locale,
      group: (state) => state.cms.group
    }),
    dataValidToSendRequest() {
      return this.resourceDetails.title && this.resourceDetails.locale;
    },
    idFromUrl() {
      return this.$route.params.id;
    }
  },
  methods: {
    goToPage(name) {
      this.$router.push({name: name});
    },
    sendDataAndReloadResourcesTree(actionName, payload) {
      this.$store.dispatch(actionName, payload)
      .then(resp => {
        this.$store.commit('cms/SET_IS_EDIT_RESOURCE', false);
        this.$store.dispatch('cms/getResourceStructure').then(() => {
          this.$store.commit('cms/SET_PARENT_ID_TO_OPEN_TREE', resp.parentId);
        });

        this.$store.commit('cms/SET_SHOW_VALIDATION_STATUS', false);

        this.$router.push({name: 'cms-general', params: {id: resp.id}});

        alertify.success(this.$t('alert.success'));
      })
      .catch(() => {
        alertify.error(this.$t('alert.error'));
      });
    },
    createOrEditResource() {
      this.$store.commit('cms/SET_SHOW_VALIDATION_STATUS', true);

      if(this.dataValidToSendRequest) {
        if(this.isCreateType) {
          // eslint-disable-next-line no-unused-vars
          const { slug, id, lvl, lft, rgt, createdAt, updatedAt, deletedAt, ...payload } = this.resourceDetails;
          payload.parentId = payload.parentId ? Number(payload.parentId) : null;

          this.sendDataAndReloadResourcesTree('cms/createResource', payload);
        } else {
          // eslint-disable-next-line no-unused-vars
          const { createdAt, updatedAt, deletedAt, lvl, lft, rgt, ...payload } = this.resourceDetails;
          payload.parentId = payload.parentId ? Number(payload.parentId) : null;

          this.sendDataAndReloadResourcesTree('cms/updateResource', payload);
        }
      }

      if(!this.dataValidToSendRequest) {
        alertify.error(this.$t('alert.form_validation_error'));
      }
    }
  },
}
</script>
